import { StartLiveChatCTABasketStyleInterface } from '@thg-commerce/enterprise-components/src/LiveChat/LiveChat'
import { IconStyling } from '@thg-commerce/gravity-patterns/Header/theme'
import { ProductAccordionThemeInterface } from '@thg-commerce/gravity-patterns/ProductDescriptionAccordion/types'
import {
  Padding,
  TextEntry,
  TextStyle,
  TextStyling,
} from '@thg-commerce/gravity-theme'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import { createPageLayoutTheme } from '../pageLayout'
import { Component, PageLayout, PartialPageLayout } from '../Renderer/layout'

interface BasketWithItems extends Component<'basketWithItems'> {
  paymentIcons: {
    order: string
    margin: {
      bottom: string
    }
  }
  continueShopping: {
    order: string
    margin: {
      bottom: string
    }
  }
  subtotalValueFont?: {
    entry: TextEntry
  }
  pageGridWithItems?: {
    margin: Margin
  }
  enableDiscountedPrice?: boolean
  basketTitle?: {
    textEntry?: TextEntry
    textStyle?: TextStyle
  }
}
interface SelectYourSample extends Component<'selectYourSample'> {
  accordion: {
    title: {
      textStyle: TextStyling
      padding: Padding
    }
  }
  productDescription: ProductAccordionThemeInterface
}

export interface UnitPrice {
  price?: {
    textStyling?: TextStyling
  }
  priceWithRRP?: {
    textStyling?: TextStyling
  }
  rrp?: {
    textStyling?: TextStyling
  }
  showOnMobile?: boolean
  showRrpPrice?: boolean
  display?: string
}

export type TableComponentTypes =
  | 'image'
  | 'name'
  | 'price'
  | 'quantity'
  | 'subtotal'
  | 'delete'
  | 'discount'
  | 'supersize'
  | 'promomessage'
  | 'wishlist'
  | 'dropdown'
  | 'itemerrors'
  | 'clickandcollect'
  | 'limit'
  | 'outofstockerror'
  | 'subscriptionInfoMessage'

export interface TableComponents {
  xs: {
    order: TableComponentTypes[]
    fullWidth: TableComponentTypes[]
    enableContainerGridMobileWidth?: boolean
  }
  sm: {
    order: TableComponentTypes[]
    fullWidth: TableComponentTypes[]
    enableContainerGridMobileWidth?: boolean
  }
}

export type BasketLayout = 'inline' | 'stacked'

export interface Table extends Component<'table'> {
  icon?: IconStyling
  font: { style: TextStyle }
  gridRowGapSpacing?: number
  components?: TableComponents
  headerComponents?: TableComponentTypes[]
  layout?: BasketLayout
  liveChat?: {
    chatIcon?: {
      width: string
      height: string
      position: string
      margin: string
      top: string
      backgroundSize?: string
      backgroundPosition?: string
    }
    container?: {
      border: string
      backgroundColor: string
      boxShadow: string
    }
    textEntry: TextEntry
    textStyle: TextStyle
    startChatCTA?: StartLiveChatCTABasketStyleInterface
  }
  unitPrice?: UnitPrice
  showSubtotalLabelonMobile?: boolean
  SubscriptionFrequencyDropDown?: {
    width: string
    maxHeight?: string
  }
}
interface Referrals extends Component<'referrals'> {}
interface PaymentIcons extends Component<'paymentIcons'> {}
interface OfferSummary extends Component<'offerSummary'> {}
interface Messages extends Component<'messages'> {}
interface Discount extends Component<'discount'> {}
interface ClickAndCollectInfoModal
  extends Component<'clickAndCollectInfoModal'> {}
interface CheckoutError extends Component<'checkoutError'> {}
interface Empty extends Component<'empty'> {}

export interface BasketItemSeparatorMargin
  extends Component<'basketItemSeparatorMargin'> {
  margin: {
    top: number
    bottom: number
  }
}
export interface BasketPageLayout
  extends PageLayout<
    | BasketWithItems
    | SelectYourSample
    | Table
    | Referrals
    | PaymentIcons
    | OfferSummary
    | Messages
    | Discount
    | ClickAndCollectInfoModal
    | CheckoutError
    | Empty
    | BasketItemSeparatorMargin
  > {}

export type PartialBasketPageLayout = PartialPageLayout<BasketPageLayout>

const { styled, useTheme } = createPageLayoutTheme<BasketPageLayout>()

export { styled, useTheme }
